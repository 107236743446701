import { useMemo } from 'react';

import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { NoFilesFound } from '@/pages/overview/common/NoFilesFound';
import { OverviewPageLoader } from '@/pages/overview/common/OverviewPageLoader';
import { useOverview } from '@/pages/overview/common/utils';
import { ClausesRevisedObject } from '@/pages/overview/financing-rights/common/utils';
import { FinancingRightsContent } from '@/pages/overview/financing-rights/content/FinancingRightsContent';

export const FinancingRights = () => {
  const { matter } = useOverview();

  const dataRoomFiles = useMemo(() => {
    return matter?.dataRoomFiles;
  }, [matter]);

  const vetoDataProcessing = useMemo(() => {
    return !matter?.finishedProcessingVetoData;
  }, [matter]);

  const hasVetoData = useMemo(() => {
    const totalClauses = dataRoomFiles
      .flatMap((file) => file.vetoData)
      .map((clause) => {
        if (typeof clause.clausesRevised === 'object' && clause.clausesRevised !== null) {
          const rightsClauses = (clause.clausesRevised as ClausesRevisedObject).rights_clauses;
          return Array.isArray(rightsClauses) ? rightsClauses.length : 0;
        }
        return 0;
      });

    return totalClauses.some((length) => length > 0);
  }, [dataRoomFiles]);

  return (
    <DataRoomContextProvider>
      {vetoDataProcessing ? (
        <OverviewPageLoader pageFocusTitle="Reviewing for Financing Rights" />
      ) : hasVetoData ? (
        <FinancingRightsContent />
      ) : (
        <NoFilesFound pageFocus="Financing Rights" />
      )}
    </DataRoomContextProvider>
  );
};
