import { useState } from 'react';
import { useFilePicker } from 'use-file-picker';

import AddNewIcon from '@/assets/images/add-new-icon.svg';
import FolderIcon from '@/assets/images/folder.svg';
import UploadIcon from '@/assets/images/upload-icon.svg';
import { Dropdown } from '@/components/Dropdown';
import {
  ConfirmationDialog,
  DialogViewState,
} from '@/pages/overview/dataroom/content/common/ConfirmationDialog';
import { ConfirmNewFolderDialog } from '@/pages/overview/dataroom/content/common/ConfirmNewFolderDialog';
import { uploadNewDataRoomFiles } from '@/pages/overview/dataroom/content/common/utils';

interface DataRoomAddNewProps {
  clientMatterNumber: number;
  clientNumber: number;
  dataRoomFileNames: string[];
}

export const DataRoomAddNew = ({
  clientMatterNumber,
  clientNumber,
  dataRoomFileNames,
}: DataRoomAddNewProps) => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isFolderDialogOpen, setIsFolderDialogOpen] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const [viewState, setViewState] = useState<DialogViewState>('ready');
  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    accept: [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'application/pdf',
      'text/plain',
    ],
    multiple: true,
    onFilesSuccessfullySelected: async ({ plainFiles }) => {
      setFilesToUpload(plainFiles);
      setViewState('ready');
      setIsUploadDialogOpen(true);
    },
  });

  const closeDialog = () => {
    setIsUploadDialogOpen(false);
    setIsFolderDialogOpen(false);
  };

  const reset = () => {
    setFilesToUpload([]);
    closeDialog();
  };

  const onUploadSubmit = async () => {
    setViewState('submitting');

    try {
      await uploadNewDataRoomFiles(
        filesToUpload,
        clientMatterNumber,
        clientNumber,
        dataRoomFileNames,
      );
      reset();
    } catch (e) {
      setFilesToUpload([]);
      setViewState('errored');
    }
  };

  const options = [
    { title: 'Document Upload', icon: UploadIcon, onClick: openFilePicker },
    { title: 'New Folder', icon: FolderIcon, onClick: () => setIsFolderDialogOpen(true) },
  ];

  return (
    <div>
      <Dropdown
        title="New"
        icon={AddNewIcon}
        options={options}
        testId="add-new-button"
        isButtonDisabled={false}
        resizeAction={true}
      />
      <ConfirmationDialog
        title={'Upload Documents?'}
        submitButtonText={'Submit'}
        isOpen={isUploadDialogOpen}
        closeDialog={closeDialog}
        onSubmit={onUploadSubmit}
        onCancel={reset}
        viewState={viewState}
      />
      <ConfirmNewFolderDialog
        clientMatterNumber={clientMatterNumber}
        clientNumber={clientNumber}
        isDialogOpen={isFolderDialogOpen}
        closeDialog={closeDialog}
      />
    </div>
  );
};
