import { FileWithPath, fromEvent } from 'file-selector';
import { useCallback, useState } from 'react';
import { DropEvent, useDropzone } from 'react-dropzone';

import GenericUploadIcon from '@/assets/images/generic-upload-icon.svg';
import { FILE_UPLOAD_ACCEPT_OBJECT } from '@/common/utils';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useNewDiligenceWizard } from '@/contexts/create-new-diligence-wizard/utils';
import { Button } from '@/pages/home/common/Button';
import { DataroomUploaderList } from '@/pages/home/content/DataroomUploaderList';

const ACCEPTED_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
  'text/plain',
];

interface FileUploadNoneProps {
  onUploadDone: (files: File[]) => void;
  onFileDelete: (fileToDelete: File) => void;
  totalCount: number;
}

export const DataroomUploader = ({
  onUploadDone,
  onFileDelete,
  totalCount,
}: FileUploadNoneProps) => {
  const MAX_FILES = 1000 - totalCount;

  const {
    newDiligenceData,
    updateNewDiligenceData,
    finishDataRoomUpload,
    uploadProcessing,
    setUploadProcessing,
  } = useNewDiligenceWizard();

  const { dataRoomFiles } = newDiligenceData;

  const [dragging, setDragging] = useState(false);

  const [rejectedFiles, setRejectedFiles] = useState<FileWithPath[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[] | undefined>(dataRoomFiles);

  const formatClientName = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };

    return date.toLocaleDateString('en-US', options);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const filesToUpload = [...(uploadedFiles || []), ...(acceptedFiles || [])];
      setDragging(false);
      setUploadedFiles((prevFiles) => {
        const files = Array.isArray(prevFiles) ? prevFiles : [];
        return [...files, ...acceptedFiles];
      });
      onUploadDone(acceptedFiles);
      updateNewDiligenceData({
        client: {
          name: formatClientName(new Date()),
          number: Date.now() % 1000,
        },
        name: formatClientName(new Date()),
        number: Date.now() % 1000,
        dataRoomFiles: filesToUpload,
      });
    },

    [onUploadDone, updateNewDiligenceData, uploadedFiles],
  );

  const getFilesOrFolders = async (event: DropEvent) => {
    const files = await fromEvent(event);
    // since user can in theory drag entire filesystem, we'll aggressively truncate and filter files early
    const validFiles = files
      .slice(0, MAX_FILES * 10)
      .filter(
        (f) =>
          ACCEPTED_TYPES.includes(f.type) &&
          (f as FileWithPath)?.path !== undefined &&
          (f as FileWithPath)?.size < 5000000,
      );
    const rejects = files
      .slice(0, MAX_FILES * 10)
      .filter(
        (f) => !validFiles.includes(f) && f.type !== '' && (f as FileWithPath)?.path !== undefined,
      );
    setRejectedFiles((rejectedFiles) => [...rejectedFiles].concat(rejects as FileWithPath[]));
    return validFiles.slice(0, MAX_FILES);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES,
    accept: FILE_UPLOAD_ACCEPT_OBJECT,
    getFilesFromEvent: (event) => getFilesOrFolders(event),
    noClick: true,
    noKeyboard: true,
    onDragLeave: () => setDragging(false),
  });

  const handleSubmit = () => {
    setUploadProcessing(true);
    finishDataRoomUpload();
  };

  const dragOverListener = useCallback(() => {
    setDragging(true);
  }, []);

  return (
    <div className="relative flex w-full flex-col pb-[50px] pt-6" data-testid="dataroom-uploader">
      <div className="flex items-baseline justify-between">
        <h1 className="text-[25px] font-bold text-marveri-white">Upload New Document Set</h1>
        {totalCount > 0 && !dragging && (
          <span className="text-[14px] font-semibold text-marveri-muted-silver">
            Drag & Drop to upload more files
          </span>
        )}
        <div>
          {uploadedFiles === undefined ||
          (uploadedFiles.length === 0 && rejectedFiles.length === 0) ? (
            <div
              className="text-center text-marveri-light-silver"
              data-testid="dataroom-uploader-no-docs"
            >
              No Documents Uploaded
            </div>
          ) : (
            <div className="pt-[16px]">
              <div className="flex">
                <h1 className="font-semibold text-marveri-white">{`${uploadedFiles?.length} File${
                  uploadedFiles?.length > 1 ? `s` : ''
                } Successfully Uploaded`}</h1>
                {rejectedFiles && rejectedFiles.length !== 0 && (
                  <>
                    <span className="px-[5px] font-semibold text-marveri-white">{`/`}</span>
                    <h1 className="font-semibold text-marveri-red">{`${rejectedFiles.length} File${
                      rejectedFiles.length > 1 ? 's' : ''
                    } Rejected`}</h1>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full gap-4">
        <div className="w-full pt-[16px] ">
          {totalCount + rejectedFiles.length === 0 || isDragActive || dragging ? (
            <div
              {...getRootProps()}
              className={`relative flex h-[260px] cursor-not-allowed flex-col items-center justify-center rounded-lg border border-dashed border-dark-border bg-container-light shadow ${
                isDragActive ? 'border-blue-500 bg-container-selected' : ''
              }`}
            >
              <form>
                <input {...getInputProps()} />
              </form>
              <div className="flex flex-col items-center justify-center py-[10px]">
                <div className="flex flex-col items-center text-center">
                  <img src={GenericUploadIcon} alt="icon" />
                  <p className="p-2 pb-[7px] text-[18px] font-bold text-marveri-white">
                    Drag & Drop Files
                  </p>
                  <div>
                    <div>
                      <p className="text-[12px] font-bold text-[#737373]">
                        Supported file formats: <br /> .docx, .doc, .pdf
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div onDragOver={dragOverListener}>
              <DataroomUploaderList
                onFileDelete={onFileDelete}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                rejectedFiles={rejectedFiles}
                setRejectedFiles={setRejectedFiles}
              />
            </div>
          )}
        </div>
      </div>
      <div className="absolute bottom-[-8px] right-0">
        <Button disabled={totalCount <= 0 || uploadProcessing} onClick={handleSubmit}>
          {uploadProcessing ? <LoadingSpinner /> : <span>Create Project</span>}
        </Button>
      </div>
    </div>
  );
};
