import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Markup } from 'react-render-markup';

import { useRedlinesContext } from '@/contexts/overview/redlines/utils';
import { Delete } from '@/pages/overview/redline/diffing/diff/Delete';
import { Insert } from '@/pages/overview/redline/diffing/diff/Insert';
import { MoveDelete } from '@/pages/overview/redline/diffing/diff/MoveDelete';
import { MoveInsert } from '@/pages/overview/redline/diffing/diff/MoveInsert';
import { Replace } from '@/pages/overview/redline/diffing/diff/Replace';
import { MarkupComponentProps } from '@/pages/overview/redline/diffing/diff/types';
import {
  Change,
  RedlineDiffingAlternateChangesModal,
} from '@/pages/overview/redline/diffing/RedlineDiffingAlternateChangesModal';

const RedlineDiffingContent = () => {
  const [selectedChangeId, setSelectedChangeId] = useState<string[]>([]);
  const [selectedChanges, setSelectedChanges] = useState<Change[]>([]);
  const { setComparingFiles, focusedFiles, setFocusedFiles, setFocusedOperation, diff } =
    useRedlinesContext();

  const unsetFocusedFiles = useCallback(() => {
    setFocusedFiles([]);
    setFocusedOperation(undefined);
  }, [setFocusedFiles, setFocusedOperation]);
  useEffect(() => {
    if (!diff || !selectedChangeId) {
      return;
    }
    const changes = selectedChangeId.map((fileName) => {
      return {
        id: fileName,
        inserted: '',
        deleted: '',
        fileName: fileName,
        type: '',
      };
    });
    setSelectedChanges(changes);
  }, [selectedChangeId, diff]);

  const addLinebreaks = (pdf: string) => {
    return pdf.replace(/\n/g, '<br/>');
  };

  const onMouseEnter = useCallback(
    (fileIds: string[], operation: string) => {
      if (!diff) return;
      setFocusedOperation(operation);
      setFocusedFiles(fileIds);
    },
    [diff, setFocusedOperation, setFocusedFiles],
  );

  const onPairwise = useCallback(
    (fileIds: string[]) => {
      if (!diff) return;
      setComparingFiles(fileIds);
    },
    [diff, setComparingFiles],
  );

  const getIsFocused = (fileIds: string[]) => {
    if (!diff) return false;
    return fileIds.some((id) => focusedFiles.includes(id));
  };

  const replace = {
    insert: ({ document_ids, ...props }: MarkupComponentProps) => (
      <Insert
        document_ids={document_ids || ''}
        {...props}
        relatedFileIds={document_ids?.split(':::') || []}
        changes={(document_ids?.split(':::') || []).length - 1}
        onPairwise={onPairwise}
        focused={getIsFocused(document_ids?.split(':::') || [])}
        onSelectChange={setSelectedChangeId}
        onMouseEnter={onMouseEnter}
        onMouseLeave={unsetFocusedFiles}
      />
    ),
    delete: ({ document_ids, ...props }: MarkupComponentProps) => (
      <Delete
        document_ids={document_ids || ''}
        relatedFileIds={document_ids?.split(':::') || []}
        {...props}
        changes={(document_ids?.split(':::') || []).length - 1}
        onPairwise={onPairwise}
        focused={getIsFocused(document_ids?.split(':::') || [])}
        onSelectChange={setSelectedChangeId}
        onMouseEnter={onMouseEnter}
        onMouseLeave={unsetFocusedFiles}
      />
    ),
    move_insert: ({ document_ids, ...props }: MarkupComponentProps) => (
      <MoveInsert
        document_ids={document_ids || ''}
        {...props}
        relatedFileIds={document_ids?.split(':::') || []}
        changes={(document_ids?.split(':::') || []).length - 1}
        onPairwise={onPairwise}
        focused={getIsFocused(document_ids?.split(':::') || [])}
        onSelectChange={setSelectedChangeId}
        onMouseEnter={onMouseEnter}
        onMouseLeave={unsetFocusedFiles}
      />
    ),
    move_delete: ({ document_ids, ...props }: MarkupComponentProps) => (
      <MoveDelete
        document_ids={document_ids || ''}
        relatedFileIds={document_ids?.split(':::') || []}
        {...props}
        changes={(document_ids?.split(':::') || []).length - 1}
        onPairwise={onPairwise}
        focused={getIsFocused(document_ids?.split(':::') || [])}
        onSelectChange={setSelectedChangeId}
        onMouseEnter={onMouseEnter}
        onMouseLeave={unsetFocusedFiles}
      />
    ),
    replace: ({ document_ids, ...props }: MarkupComponentProps) => (
      <Replace
        document_ids={document_ids || ''}
        relatedFileIds={document_ids?.split(':::') || []}
        {...props}
        changes={(document_ids?.split(':::') || []).length - 1}
        onSelectChange={setSelectedChangeId}
        onPairwise={onPairwise}
        focused={getIsFocused(document_ids?.split(':::') || [])}
        onMouseEnter={onMouseEnter}
        onMouseLeave={unsetFocusedFiles}
      />
    ),
    h1: ({ ...props }: MarkupComponentProps) => (
      <div className="text-center text-[20px]" {...props}>
        <strong>{props.children}</strong>
      </div>
    ),
    h2: ({ ...props }: MarkupComponentProps) => (
      <div className="text-[16px]" {...props}>
        <strong>{props.children}</strong>
      </div>
    ),
    header: ({ ...props }: MarkupComponentProps) => (
      <header className="text-[12px]" {...props}>
        {props.children}
      </header>
    ),
    footer: ({ ...props }: MarkupComponentProps) => (
      <footer className="text-[12px]" {...props}>
        {props.children}
      </footer>
    ),
    div: ({ ...props }: MarkupComponentProps) => (
      <div className="indent-12" {...props}>
        {props.children}
      </div>
    ),
  };
  return (
    <>
      {selectedChangeId.length > 0 && (
        <RedlineDiffingAlternateChangesModal
          isModalOpen={true}
          setIsModalOpen={() => setSelectedChangeId([])}
          changes={selectedChanges}
        />
      )}
      <div>
        <div
          className="mx-auto h-[calc(100vh-100px)] w-[calc(98vw-234px-314px-75px)] cursor-auto
    overflow-y-auto bg-[white] px-[80px] py-[100px] text-lg
    scrollbar-thin scrollbar-thumb-marveri-muted-silver"
          onClick={(e: SyntheticEvent) => e.stopPropagation()}
          onMouseMove={unsetFocusedFiles}
        >
          {diff && <Markup markup={addLinebreaks(diff)} replace={replace} />}
        </div>
      </div>
    </>
  );
};
export default RedlineDiffingContent;
