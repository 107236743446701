import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import chunk from 'lodash/chunk';

import { DataRoomClientFile } from '@/common/types';
import {
  getPresignedPostAndUpload,
  renameDuplicateFiles,
  renameGivenExistingNames,
  sanitizeFileName,
} from '@/common/utils';
import { CHUNK_SIZE } from '@/pages/overview/dataroom/content/common/constants';
import { FilesByPath } from '@/pages/overview/dataroom/content/common/types';
import { trpcClient } from '@/utils/trpc';

export async function uploadNewDataRoomFiles(
  newDataRoomFiles: File[],
  clientMatterNumber: number,
  clientNumber: number,
  existingDataRoomFileNames: string[] = [],
) {
  const files = renameDuplicateFiles(newDataRoomFiles, existingDataRoomFileNames);

  const dataRoomUploads = files.map((file) => {
    return getPresignedPostAndUpload(file, clientNumber, clientMatterNumber);
  });

  const uploadResults = await Promise.all(dataRoomUploads);

  // We don't need to wait for this to finish because the subscription to
  // the client matter details will update the UI
  trpcClient.dataRoom.addAdditionalDataRoomFiles.mutate({
    files: uploadResults,
    clientNumber,
    clientMatterNumber,
  });
}

async function getPresignedGets(
  matterNumber: number,
  clientNumber: number,
  files: DataRoomClientFile[],
) {
  return Promise.all(
    chunk(files, CHUNK_SIZE).map(async (chunk) => {
      return await trpcClient.aws.getPresignedGets.query({
        matterNumber,
        clientNumber,
        files: chunk.map((file) => ({
          name: file.name,
          // Download the original versions
          pdf: false,
        })),
      });
    }),
  );
}

export async function downloadUnorganizedDataRoomFiles(
  matterNumber: number,
  clientNumber: number,
  clientName: string | null,
  files: DataRoomClientFile[],
) {
  const response = (await getPresignedGets(matterNumber, clientNumber, files)).flat();

  const contentName = `${clientName}-${matterNumber}.zip`;

  const zip = new JSZip();
  const zipFolder = zip.folder(contentName);

  if (zipFolder === null) {
    throw new Error('Error creating zip folder');
  }

  await Promise.all(
    response.map(async ({ url, name }) => {
      const res = await axios.get(url, { responseType: 'blob' });
      const displayName = files.find((file) => file.name === name)?.displayName || name;
      zipFolder.file(displayName, res.data);
    }),
  );

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, contentName);
}

export async function downloadOrganizedDataRoomFiles(
  matterNumber: number,
  clientNumber: number,
  clientName: string | null,
  inputFilesByPath: FilesByPath,
  selectedFoldersArray: string[],
) {
  const filesByPath = { ...inputFilesByPath };
  delete filesByPath['/~Trash'];

  const files = Object.values(filesByPath)
    .flat()
    .filter((file) => !!file);

  const response = (
    await getPresignedGets(
      matterNumber,
      clientNumber,
      files.filter((f) => f.docType !== 'Cap Table'),
    )
  ).flat();

  clientName = clientName?.split('/').join('-') || 'Dataroom';

  const contentName =
    selectedFoldersArray.length === 1
      ? `${selectedFoldersArray[0]}`
      : selectedFoldersArray.length > 1
        ? `${clientName}-${matterNumber}`
        : 'Dataroom Files';

  const zip = new JSZip();
  const zipFolder = zip.folder(contentName);

  if (zipFolder === null) throw new Error('Error creating zip folder');

  const addFilesToZip = async (pathFiles: DataRoomClientFile[], folder: JSZip) => {
    const usedFileNames = [] as string[];
    for (const file of pathFiles) {
      const url = response.find((item) => item.name === file.name)?.url;
      if (!url) throw new Error(`No presigned url found for file: ${file.name}`);
      const res = await axios.get(url, { responseType: 'blob' });

      const fileName = sanitizeFileName(file.displayName);
      const newName = renameGivenExistingNames(fileName, usedFileNames);

      usedFileNames.push(newName);

      folder.file(newName, res.data);
    }
  };

  if (selectedFoldersArray.length > 1) {
    for (const path in filesByPath) {
      const pathFolder = zipFolder.folder(path);
      if (pathFolder === null) throw new Error('Error creating zip folder');
      await addFilesToZip(filesByPath[path], pathFolder);
    }
  } else {
    await addFilesToZip(files, zipFolder);
  }

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, contentName);
}

export const setMenuPosition = (heightRatio: number, xPosition: number, yPosition: number) => {
  if (heightRatio > 0.85) {
    return { top: `${yPosition - 100}px`, left: `${xPosition}px` };
  }
  return { top: `${yPosition}px`, left: `${xPosition}px` };
};

export const setPracticeAreaMenuPosition = (heightRatio: number, widthRatio: number) => {
  if (heightRatio > 0.7 && widthRatio > 0.6) {
    return '-bottom-[0.6rem] right-[11rem]';
  }
  if (heightRatio > 0.7) {
    return '-bottom-[0.6rem] left-[11rem]';
  }
  if (widthRatio > 0.6) {
    return '-top-[0.6rem] right-[11rem]';
  }
  return '-top-[0.6rem] left-[11rem]';
};
